.cover_photo_card {
  margin-top: 0;
  padding-top: 63px;
  padding-inline: 20px;
  --background: var(--bg-color);
}

.card-container {
  padding-block: 30px;
  display: flex;
  flex-direction: column;
  padding-inline: 26px 19px;
  gap: 30.71px;
}

.card-container .card-heading-lg {
  font-family: "Montserrat", sans-serif;
  font-size: 21px;
  font-weight: 600;
  line-height: 15px;
  color: var(--text-color);
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.justify-contain {
  justify-content: space-between;
}

.link_card {
  padding: 20px 18px;
  background-color: var(--bg-color);
}

.cover_photo_container {
  max-height: 283px;
  /* position: relative; */
  overflow: hidden;
  z-index: 10;
}

.cover_photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.ion-icon {
  margin-right: 14px;
}

.icon_text {
  margin-left: 14px;
  line-height: 14px;
  font-weight: 500;
  color: var(--text-color);
  /* font-weight: 600; */
}

.office_icon_text {
  line-height: 21px !important;
  max-width: 230px;
}

ion-label {
  margin: 0;
}

ion-icon {
  margin-right: 0;
}

ion-card-header.ios {
  display: flex;
  flex-flow: column-reverse;
}

ion-segment-button::before {
  all: unset;
}

.joi-avatar-icon {
  width: 100%;
  left: 0px;
  border-radius: 0;
  border: none;
}

.avatar-icon {
  border: 5px solid var(--bg-color);
  background-color: var(--bg-color);
  position: absolute;
  left: 19px;
  top: 238px;
  border-radius: 50%;
  /* bottom: -40px; */
  width: 100px;
  height: 100px;
  z-index: 8;
}

ion-card-subtitle {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28.5958px;
  line-height: 33px;
  color: var(--text-color);
  text-transform: inherit;
}

ion-card-content {
  padding-bottom: 0px !important;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px !important;
  line-height: 16px !important;
  color: #000000;
}

.ion_text {
  color: var(--text-color);
  display: flex;
  gap: 10px;
  align-items: center;
}

.ion_text p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: var(--text-color);
}

ion-card {
  margin: 0px;
  padding-bottom: 20px;
  border-radius: 0px;
  box-shadow: none;
  border-bottom: 1px solid #c4c4c4;
}

.content-card-heading {
  color: var(--text-color);
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 15.27px;
}

.card-heading {
  color: var(--text-color);
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 21px;
  line-height: 14px;
}

ion-segment {
  padding-left: 0px;
  background-color: var(--bg-color);
  border-radius: 0px;
}

.social-media-icon {
  width: 50px;
  height: 50px;
}

.ion-accordion-toggle-icon {
  color: #b6b6b6 !important;
}

.portfoli-card-title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px 23px 0px 26px;
}

.card-heading-lg {
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 600;
  color: var(--text-color);
}

ion-accordion.accordion-expanded>[slot="header"] .ion-accordion-toggle-icon {
  transform: rotate(90deg);
  color: #b6b6b6;
}

.ion-content {
  display: flex;
  flex-direction: column;
  gap: 11px;
  padding-inline: 0;
}

.ion-btn {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  --padding-block: 10px;
  --border-radius: 30px;
  --background: #0047ff;
}

.ion-label {
  margin-bottom: 10px;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 19px !important;
}

.line-btn {
  width: 60px;
  height: 2px;
  background-color: #484953;
  margin: 0 auto;
}

.outline-btn {
  color: var(--create-button);
  --border-color: #4a4a57;
  font-weight: 600;
  font-size: 15;
  line-height: 17px;
  font-family: "Poppins";
  --border-radius: 30px;
  text-transform: capitalize;
}

.accordion-header {
  --border-width: 0 0 0 0 !important;
}

.blog_links {
  display: flex;
  flex-direction: row;
}

.blog_links_head {
  display: flex;
  flex-direction: column;
  padding-left: 17.71px;
}

.blog_links_head ion-label {
  font-weight: 400;
}

.blog_links svg {
  flex-shrink: 0;
}

.view-all-button {
  --padding-start: 16px;
  --padding-end: 16px;
  --padding-top: 12px;
  --padding-bottom: 12px;
  --color: #71757b;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 9px;
  --border-color: #71757b;
  --border-width: 1px;
  --border-style: solid;
}

.ion-label-descripttion {
  font-weight: 400;
  font-size: 13px !important;
  line-height: 16px;
}

.profile-header-container {
  background-color: var(--bg-color);
  display: flex;
  justify-content: space-between;
  padding: 22px;
  align-items: center;
}

.header-image {
  object-fit: cover;
  width: 54px;
  height: 54px;
  border-radius: 24px;
  flex-shrink: 0;
  padding: 5px;
}

.back-btn {
  --background: transparent !important;
  --background-activated: transparent !important;
  --background-hover: transparent !important;
  --background-focused: transparent !important;
  --box-shadow: none;
  margin: 0px;
  width: 20px;
  height: 16px;
}

.back-btn::part(native) {
  padding: 0px;
}

.sticky-header {
  position: sticky;
  z-index: 11;
  -webkit-backdrop-filter: saturate(200%) blur(20px);
  backdrop-filter: saturate(200%) blur(10px);
  top: 0;
}

/* Info Button */
.info-button {
  position: fixed;
  height: 68px;
  width: 68px;
  right: 10px;
  bottom: 53px;
  box-shadow: 10.906106px 0.906106px 18.1221px 0.906106px rgba(0, 71, 255, 0.35);
  border-radius: 100%;
}

.info-button>ion-fab-button {
  height: 68px;
  width: 68px;
}

.info-button ion-fab-list {
  right: 6px;
}

.info-button ion-icon {
  color: #000000;
}
.linear-bg {
  position: absolute;
  inset: 155px 0 0;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0, 1) 98%);
  z-index: -2;
}

.blue {
  position: absolute;
  left: -0.27%;
  right: 0%;
  top: 51.39%;
  bottom: -0.16%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) -2.04%, #161a2e 45.94%);
  z-index: -2;
}

.hlyx {
  position: absolute;
  left: -0.27%;
  right: 0%;
  top: 51.6%;
  bottom: -0.12%;
  z-index: -2;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 74.47%);
}

.james {
  position: absolute;
  inset: 155px 0 0;
  z-index: -2;
}

.joi-company-logo-section {
  position: absolute;
  top: 0px;
  left: 0px;
}

.joi-company-logo {
  border-radius: 0;
}

.cardContainer {
  height: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding-bottom: 100px;
}

h1 {
  font-family: "Montserrat", sans-serif;
  text-align: start;
  font-weight: 700;
  font-size: 38px;
  line-height: 44px;
  max-width: 265px;
  margin: 0;
  margin-top: 20px;
}

.detail-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.detail {
  display: flex;
  align-items: baseline;
  gap: 8px;
}

.position-text {
  text-align: left;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #ffffff;
  margin: 0;
}

.location-text {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: #ffffff;
}

/* .company-logo-section {
  height: 90px;
  width: 90px;
} */

.company-logo {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.card-footer {
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-block: 36px 32px;
  background: rgb(60 77 95 / 50%);
  border-top: 1px solid rgba(255, 255, 255, 0.19);
  border-radius: 30px 30px 0 0;
}

.detail-modal {
  --border-radius: 30px;
  --background: rgb(60 77 95 / 50%);
  --border-top: 1px solid rgba(255, 255, 255, 0.19);
}

.footer-btn {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 17px;
  line-height: 18px;
  min-width: 138px;
  --background: white;
  --color: black;
  --padding: 13px 18px;
  --border-radius: 11px;
}

.link-Button-section {
  min-width: -webkit-fill-available;
  position: absolute;
  bottom: 0px;
  height: 86.57px;
  /* mix-blend-mode: lighten; */
}

/* Add/Message Buttons Start */
.link-Button-section .button-section {
  margin: 0 !important;
  background-color: #0000;
  /* background: rgb(37, 39, 49, 0.5); */
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  /* border: 1px solid #0000; */
  /* height: 78.58px; */
  /* border-top-left-radius: 11px; */
  /* border-top-right-radius: 11px; */
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #181A1C;
}

.link-Button-section .button-section .buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
}

.link-Button-section .button-section .buttons .inner-btn {
  width: 158.86px;
  height: 45px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
}

/* Add/Message Button End */
.link-Button .linkButton-Card {
  padding: 0 10px 20px 10px !important;
}

.logoName h1 {
  min-width: 260px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 44px;
  color: #ffffff;
  margin-top: 10px;
}

.profile-footer-button {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
  width: 158px;
  height: 45px;
  transform: "rotate(-360deg)";
  --border-radius: 30px;
  text-transform: capitalize;
  /* padding-inline: 10px; */
}

.white_profile {
  border: 1px solid #FFFFFF;
  border-radius: 30px;
  background-color: transparent;
}

.dim {
  opacity: 0.4;
}
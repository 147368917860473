.bgBlack {
  background-color: var(--bg-color);
  height: 100vh;
  padding: 0 7%;
  display: flex;
  flex-direction: column;
}

.profile_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15% 0;
}

.location_div {
  display: flex;
  gap: 3px;
  margin-top: 5px;
}

.border {
  border: 1px solid #3b3d40;
}

.privateProfile_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10% 0;
  gap: 10px;
}

.profilePic {
  height: 125px;
  width: 120px;
  border-radius: 58.7696px;
  object-fit: cover;
}

.RequestAccessBtn {
  background: #ffffff;
  border-radius: 50px;
  padding: 14px 15px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #000000;
}

.RequestAccessBtn_div {
  display: flex;
  justify-content: center;
  margin-bottom: auto;
}

.name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16.8801px;
  text-align: center;
  color: #ffffff;
}

.position {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11.6862px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
}

.office_name {
  font-weight: 700;
  color: #ffffff !important;
}

.location_name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11.6862px;
  text-align: center;
  color: #ffffff;
  opacity: 0.8;
}

.privateProfile {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11.6862px;
  text-align: center;
  color: #ffffff;
  opacity: 0.8;
}

.pd-20 {
  padding: 20%;
}

.visibility_none {
  visibility: hidden;
}

.createAnAccountModal {
  background: #383838;
  box-shadow: 0px 20px 40px rgba(37, 38, 94, 0.101961);
  border-radius: 22px;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 0;
  left: 0;
  margin: 7%;
  top: 0;
}

.create {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
}

.create_p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #b7b7b7;
  padding: 0% 8%;
}

.btns {
  display: flex;
  gap: 9.09px;
  margin: 30px 0 20px 0;
}

.createBtn {
  padding: 11.8218px 21.8248px;
  background: #186bfe;
  box-shadow: 0px 9.09366px 18.1873px rgba(0, 0, 0, 0.0509804);
  border-radius: 21.8248px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13.6405px;
  text-align: center;
  color: #ffffff;
}

.dismissBtn {
  padding: 12px 22px;
  background: #ffffff;
  box-shadow: 0px 9.09366px 18.1873px rgba(0, 0, 0, 0.0509804);
  border-radius: 21.8248px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13.6405px;
  text-align: center;
  color: #000000;
}

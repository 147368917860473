.main_div {
    height: 100%;
    overflow: auto;
    position: absolute;
    left: 0;
    top: 5%;
    right: 0;
    z-index: 12;
    background: #181A1C;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    transition: opacity 0.3s ease-in-out;
}

.submain_div {
    height: 95%;
    overflow: auto;
}

.linkWith {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 0;
}

.linkWith_span {
    text-transform: capitalize;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}

.linkWith_name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    margin-top: 5px;
}

.editCam_div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blackBG {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    border-radius: 50px;
    width: 100px;
    background-image: url('../../assets/BlackBg.svg');
    position: relative;
}

.pencilIcon {
    position: absolute;
    bottom: 0;
    right: 0;
}

.inputField_div {
    padding: 30px 30px 0 30px;
}

.label_field {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    text-transform: uppercase;
    color: #777777;
}

.inputField {
    border: none;
    outline: none;
    background: none;
    padding: 12px 0;
    width: 100%;
    resize: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
}

.inputField::placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #B2B7BF;
}

.border {
    border: 1px solid #777777;
}

.plus_btn_div {
    display: flex;
    gap: 10px;
    padding: 11.6667px 17.5px;
    background: #303336;
    border-radius: 41.6667px;
    width: fit-content;
    align-items: center;
}

.moreDetails_btn {
    background: #303336;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 13.3333px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
}

.blue_bg {
    background: #1141BF;
}

.button_div {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 15px;
    margin: 30px 0;
}

.sendContact_btn {
    background: transparent;
    border-radius: 30px;
    border: 1px solid white;
    padding: 12px 20px;
}

.sendContact {
    background: transparent;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
}

.location_state_div {
    display: flex;
    align-items: end;
    justify-content: space-between;
}

.city {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #777777;
    margin-top: 10px;
}

.mb-5 {
    margin-bottom: 5px;
}

.relative {
    position: relative;
}

.editIcon {
    position: absolute;
    top: 18px;
    right: 0px;
}

.staric {
    margin-bottom: 3px;
}

.Dp {
    height: inherit;
    width: inherit;
    border-radius: 50px;
    object-fit: cover;
}

.options_div {
    position: absolute;
    width: 205px;
    height: 79px;
    background: #2C2D2E;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    top: -8px;
    text-align: center;
}

.border_grey {
    border: 1px solid #858585;
}

.options_p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #FFFFFF;
    padding: 10px;
    background: none
}

.options_retake {
    display: flex;
    justify-content: space-evenly;
}

.cross_div {
    display: flex;
    justify-content: flex-end;
    margin: 7% 7% 0 0px;
}

.cross_div button {
    background: none;
    border: none;
}

.loader_div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
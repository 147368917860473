.container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: max-content;
  justify-content: flex-start;
  overflow-x: scroll;
  background-color: var(--bg-color);
  width: 100%;
  gap: 10px;
}

.container::-webkit-scrollbar-thumb {
  background-color: #f10000;
  border-radius: 6px;
}

.eye_icon {
  position: absolute;
  left: 15px;
  top: 15px;
}
.image_desc {
  position: absolute;
  bottom: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.83) 5.16%,
    rgba(0, 0, 0, 0.58) 33.97%,
    rgba(0, 0, 0, 0) 68.28%
  );
}
.inner_desc {
  font-family: "Poppins";
  margin-bottom: 10px;
  margin-left: 10px;
}
.image_details {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  width: 70%;

  color: #dfe3ea;
}
.container img {
  /* max-width: 100%; */
  display: block;
  object-fit: cover;
  /* max-width: 10rem; */
  border-radius: 6px;
}

/* .box:nth-child(10n + 1) {
  height: 10rem;
}

.box:nth-child(10n + 2) {
  height: 10rem;
}

.box:nth-child(10n + 3) {
  height: 100%;
}

.box:nth-child(10n + 4),
.box:nth-child(10n + 9) {
  height: 12rem;
}

.box:nth-child(10n + 5),
.box:nth-child(10n) {
  height: 8rem;
}

.box:nth-child(10n + 6) {
  height: 10rem;
}

.box:nth-child(10n + 7) {
  height: 10rem;
}

.box:nth-child(10n + 8) {
  height: 100%;
} */
